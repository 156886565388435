import React, { useState, useEffect, useCallback } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DescriptionIcon from '@material-ui/icons/Description';
import Paper from '@material-ui/core/Paper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent:"center",
    backgroundColor: "#fff",
    height: "100px",
    widthfontWeight: "bold",
    fontSize: "20px"
  },
  grid_tituloSeccion: {
    color: "#222222",
    backgroundColor: "#BFBFBF",
    paddingLeft: "50px",
    marginLeft: "20px",
    marginRight: "10px",
    marginTop: "30px",
    borderRadius: "10px/10px",

  },
  h3_tituloSeccion: {
    color: "#222222",
    fontSize: "20px"

  },
  labelPeriodo: {
    marginTop: "20px",
    float: "left"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: "80px"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PagoExpressOk() {
  const classes = useStyles();
  const [codigo, setCodigo] = useState("");
  const [isLoading] = useState(false);
  const [snackOpenError, setSnackOpenError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [allComm, setAllComm] = useState( [ ] );
  const [isLoadingDef, setIsLoadingDef] = useState( true );
  const [actualComm, setActualComm] = useState( { } );
  const [actualUnidad, setActualUnidad] = useState( { } );
  const { id } = useParams();
  const [boleta, setBoleta] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [state, setState] = useState(0);
  const [idTx, setIdTx] = useState({});

  useEffect( async() => {
      try { 
          if ( id == null || id.length == 0 ) {
            setState(1);
            return 1;
          }
          let idTx = await API.post('ePlat', '/validar_pago', {
            body: {
              "idTx": id
            } 
          });
  
          console.log("--->> IdTx:" + JSON.stringify(idTx));
          if ( idTx == null || idTx.id_transaccion != id ) {
            setState(1);
          } else {
            setIdTx(idTx);
            setState(2);
          }
  
      } catch (e) {
        console.log("Error validar IdTx:" + e.toString());
        setState(1);
      }

  }, [id]);

class StateMesssage extends React.Component {

    
    render() {
      // Estados de variable: state
      // 0 : Buscando comprobante
      // 1 : Comprobante NO encontrado
      // 2 : Comprobante encontrado

      const messages = [
        "Buscando comprobante...",
        "Problemas con el pago, comprobante no encontrado",
        "Pago Exitoso"];
      const state = this.props.state;

      return (
        <Grid container  style={{ marginLeft: "100px", marginTop: "30px", width: "80%"}}>
            <Grid item xs={12}>
                <Paper className={classes.paper}> 
                  {(state==2) && <CheckCircleIcon fontSize="large"/>}
                  {messages[state]}
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <label
                  className={classes.labelPeriodo}
                  style={{ fontWeight: "bold", marginTop: "10px", marginLeft: "10px" }}>
                  Comprobante: {id}
                </label>
            </Grid>
        </Grid>
      );
    }
}


  function handleChangeComunidad(e) {
    e.persist();
    e.preventDefault();
    let c = allComm.find( o => o.id === e.target.value);
    setActualComm(c);
    setActualUnidad({});
    setBoleta([]);
  }

  function handleChangeUnidad(e) {
    e.persist();
    e.preventDefault();
    setActualUnidad(actualComm.unidads.find( o => o.id === e.target.value));
    setBoleta([]);
  }

  function codeService() {
    if (actualUnidad != null && actualUnidad.codigo_servicio != null )  return true;
    return false;
  }

const [checked, setChecked] = React.useState(false);

const handleEnablePayment = (event) => {
  setChecked(event.target.checked);
};


let initialFValues = {
  "depto": "",
}

const [valoresFormulario, setValoresFormuario] = useState(initialFValues);
const [firstTime, setFirstTime] = React.useState(true);

const handleInputChange = e => {

  // console.log("E", e);
  const { name, value } = e.target;

  setValoresFormuario({
    ...valoresFormulario,
    [name]: value

  });

};


  function handleEventChangeCodigo(e) {
    e.persist();
    setCodigo(e.target.value);
  }


  async function handleBuscarBoleta(e) {
    e.preventDefault();
  }

  
  const obtenerBoletaPorDepto = useCallback(async (comunidadId, nombreUnidad) => {
    let result; 
    try {
          result = API.post('ePlat', '/get_boleta_pago', {
            body: {
              "depto": nombreUnidad,
              "comunidad": comunidadId
            }
          });
    } catch (e) {
      console.log(e);
    } 

      boleta.setRowData(result.boletas);
      setTimeout(function () { boleta.refreshCells(); }, 0)
}, [boleta]);

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpenError(false);
  };

  const step =  0;

  //console.log(step, "STEP");
  
  return (
    <div>
      <Snackbar open={snackOpenError} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Container style={{minWidth: "100%"}}>
        <CssBaseline />
      <Grid container >
            <Grid container className={classes.grid_tituloSeccion}>
              <Grid item>
                <DescriptionIcon style={{ marginTop: "22px", marginRight: "10px" }} />
              </Grid>
              <Grid item xs={2}>
                <h3 className={classes.h3_tituloSeccion}>Pago Express</h3>
              </Grid>
            </Grid>
            <StateMesssage state={state} />
      </Grid>
      </Container>
    </div>
  );
}

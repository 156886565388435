import React, { useState, useEffect, useCallback } from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LinearProgress from "@material-ui/core/LinearProgress";
import { API } from "aws-amplify";
import { useLocation, useParams } from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DescriptionIcon from '@material-ui/icons/Description';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ServipagButton from './servipag/servipagButton';
import config from "../config";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import ReactMoment from "react-moment";
import TableRow from '@material-ui/core/TableRow';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import NativeSelect from '@material-ui/core/NativeSelect';
import numeral from 'numeral';

numeral.register('locale', 'es', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  ordinal : function (number) {
      return number === 1 ? 'er' : 'ros';
  },
  currency: {
      symbol: '$'
  }
});
numeral.locale('es');



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //backgroundColor: "#fff",

  },
  no_borderBottom: {
    borderBottom: "0px"
  },

  grid_contenidoSeccion: {
    color: "#222222",
    paddingLeft: "0px",
    marginLeft: "0px",
    marginRight: "30px",
    marginTop: "10px",


  },
  labelSeccion: {
    color: "#222222",
    fontWeight: "bold",
    paddingTop: "20px",
    marginBottom: "0px",
    marginLeft: "0px",
    fontSize: "18px"
  },
  grid_tituloSeccion: {
    color: "#222222",
    backgroundColor: "#BFBFBF",
    paddingLeft: "50px",
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "30px",
    borderRadius: "10px/10px",

  },
  h3_tituloSeccion: {
    color: "#222222",
    fontSize: "20px"

  },
  labelPeriodo: {
    marginTop: "20px",
    float: "left"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: "80px"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  tbl_contenidoSeccion: {
    color: "#222222",
    backgroundColor: "#F1F1F1",
    paddingLeft: "0px",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "10px",
    borderRadius: "10px/10px",
    padding: "20px"

  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class StateBottom extends React.Component {

  render() {
    // Estados de variable: state
    // 0 : sin búsqueda aún
    // 1 : buscando boletas
    // 2 : boleta no encontrada
    // 3 : boleta ya pagada
    // 4 : pagar con servipag --> Ver si botón está disponible
    const boleta = this.props.boleta;
    const baseApiUrl = this.props.baseApiUrl;
    const messages = [
      " ",
      "Buscando boleta...",
      "No hay boletas disponibles",
      "Boleta ya pagada",
      " "];
      
    return (
      <Grid container  style={{ paddingLeft: "10px", marginLeft: "30px", marginRight: "30px", marginTop: "10px",}}>
      <Grid item xs={12}>
          <label
            className={this.props.className}
            style={{ fontWeight: "bold", marginTop: "10px", marginLeft: "10px" }}>
            {messages[this.props.state]}
          </label>
      </Grid>
      <Grid item xs={12}>
          { (this.props.state ==4 ) && (
            <div>
          <label
              className={this.props.className}
              style={{ fontWeight: "bold", marginTop: "10px", marginLeft: "-26px", marginRight: "10px" }}>
          Monto total a pagar: {numeral(boleta.monto).format('$ 0,0')} </label>

            { (this.props.isPayEnable == 1 )  ?
            <ServipagButton
                imagenServipag="/assets/img/boton_servipag.png" 
                test = {false} 
                dataPago = {{
                  "identificador": 100,
                  "folio": boleta.folio,
                  "codigo_servicio": boleta.codigo_servicio,
                  "tipo_documento": "B",
                  "monto": boleta.monto,
                  "monto_total_deuda": boleta.monto,
                  "fecha_vencimiento": boleta.fecha_vencimiento.split('T')[0],
                  "periodo_facturacion": boleta.periodo_facturacion.replace('-','')
                }}
                baseApiUrl= {baseApiUrl}
                />
                :
                <label
                className={this.props.className}
                style={{ fontWeight: "bold", marginTop: "10px", marginLeft: "20px", marginRight: "10px" }}>
                 Botón de Pago no disponible por mantención </label>
              }

          </div> 
          )}
      </Grid>
    </Grid>
    );
  }

}

export default function PagoExpress() {
  const classes = useStyles();
  const [codigo, setCodigo] = useState("");
  const [isLoading] = useState(false);
  const query = useQuery();
  const [snackOpenError, setSnackOpenError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [allComm, setAllComm] = useState( [ ] );
  const [isLoadingDef, setIsLoadingDef] = useState( true );
  const [actualComm, setActualComm] = useState( { } );
  const [actualUnidad, setActualUnidad] = useState( { } );
  const { id } = useParams();
  const [boleta, setBoleta] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [stateBottom, setStateBottom] = useState(0);
  const baseApiUrl  = config.servipag.baseApiUrl;

  useEffect( () => {
      API.get("ePlat", "/get_community")
      .then(data => {

        //Sort unidads
        console.log(JSON.stringify(data));
        if ( data != null && data.length > 0) {
          data.forEach( (obj, index) => 
                obj.unidads.sort((a, b) => a.numero - b.numero)
                )
          setAllComm(data); 
        }
        if ( id != null) {
          data.forEach( (obj, index) => {
            let u = obj.unidads.find( u => u.codigo_servicio === id);
  
            if ( u != null) {
              setActualComm(obj);
              setActualUnidad(u);
            }
          });
        }
        setIsLoadingDef(false);
      } );
  }, [id]);

  async function handleBuscarBoleta(e) {
    console.log();
    e.preventDefault();
    setStateBottom(1);
    getBoletas();
  }

  function handleChangeComunidad(e) {
    e.persist();
    e.preventDefault();
    let c = allComm.find( o => o.id === e.target.value);
    setActualComm(c);
    setActualUnidad(c.unidads[0]);
    setBoleta([]);
    setStateBottom(0);
  }

  function handleChangeUnidad(e){

    let u = actualComm.unidads.find( o => o.numero == e.target.value);
    e.persist();
    e.preventDefault();
    setActualUnidad(u);
    setBoleta([]);
    setStateBottom(0);
  }

  function codeService() {
    if (actualUnidad != null && actualUnidad.codigo_servicio != null )  return true;
    return false;
  }

const [checked, setChecked] = React.useState(false);

const handleEnablePayment = (event) => {
  setChecked(event.target.checked);
};


let initialFValues = {
  "depto": "",
}

const [valoresFormulario, setValoresFormuario] = useState(initialFValues);
const [firstTime, setFirstTime] = React.useState(true);

const handleInputChange = e => {

  // console.log("E", e);
  const { name, value } = e.target;

  setValoresFormuario({
    ...valoresFormulario,
    [name]: value

  });

};


  function handleEventChangeCodigo(e) {
    e.persist();
    setCodigo(e.target.value);
  }


  
  async function getBoletas() {
    try { 
        let result = await API.post('ePlat', '/get_boleta_pago', {
          body: {
            "depto": actualUnidad.numero,
            "comunidad": actualComm.id,
            "codigo_servicio": id,
          }
        });

        console.log("--->> Boleta:" + JSON.stringify(result.boletas));
        if ( result.boletas == null || result.boletas.length === 0 || result.boletas === undefined ) {
          //setErrorMessage("Boleta no encontrada");
          //setSnackOpenError(true);
          setStateBottom(2);
        }

        // result.boletas.monto.toLocaleString('en-US', {
        //   style: 'currency',
        //   currency: 'USD',
        // });
      
      setBoleta(result.boletas);
      console.log("boleta[0] : " + JSON.stringify(result.boletas));

      let boleta = result.boletas[0];
      if ( boleta == null ) {
        setStateBottom(2);
      } else {
          if ( boleta.idTx != null && boleta.idTx.length > 0) {
            setStateBottom(3);
          } else {
            setStateBottom(4);
          }
      }
    } catch (e) {
      console.log("getBoletas:" + e.toString());
      //setErrorMessage("Boleta no encontrada");
      //setSnackOpenError(true);
      setStateBottom(2);
    }

  }
  
  const obtenerBoletaPorDepto = useCallback(async (comunidadId, nombreUnidad) => {
    let result; 
    try {
          result = API.post('ePlat', '/get_boleta_pago', {
            body: {
              "depto": nombreUnidad,
              "comunidad": comunidadId
            }
          });
    } catch (e) {
      console.log(e);
    } 

      boleta.setRowData(result.boletas);
      setTimeout(function () { boleta.refreshCells(); }, 0)
}, [boleta]);

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpenError(false);
  };

  const step = parseInt(query.get("step") || 0);

  //console.log(step, "STEP");
  return (
    <div>
      <Snackbar open={snackOpenError} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Container style={{maxWidth: "sm"}}>
        <CssBaseline />
      <Grid container fullWidth  >
            <Grid container className={classes.grid_tituloSeccion}>
              <Grid item>
                <DescriptionIcon style={{ marginTop: "22px", marginRight: "10px" }} />
              </Grid>
              <Grid item xs={6}>
                <h3 className={classes.h3_tituloSeccion}>Pago Express</h3>
              </Grid>

            </Grid>
              { isLoadingDef ? ( <LinearProgress /> ) : (
                <div style={{ marginLeft: "0px", width: "100%", marginTop: "15px"}} >

                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} >
                      <Grid item xs={6} sm={4} md={2}>
                        <FormControl fullWidth> 
                          <InputLabel id="comunidad-select-label">Comunidad</InputLabel>
                          <Select
                            labelId="comunidad-select-label"
                            id="comunidad-search"
                            value={actualComm?.id}
                            label="Comunidad"
                            onChange={handleChangeComunidad}
                          >
                            {allComm.map(com => (
                              <MenuItem value={com.id}>{com.nombre}</MenuItem>
                            ))}
                          </Select>
                          </FormControl> 
                      </Grid>

                      <Grid item xs={6} sm={4} md={2}>
                        {/*
                          <Autocomplete
                          id="autocomplete-label"
                          options={actualComm?.unidads?.map((option) => option.numero)}
                          freeSolo  
                          style={{ width: 100}}
                          onChange={handleChangeUnidad}
                          renderInput={(params) => <TextField {...params} label="Unidad" variant="outlined" />}
                        />
                        */}
                    
                        <FormControl fullWidth>
                          <InputLabel htmlFor="unidad-select-label"> </InputLabel>

                          <NativeSelect
                              value={actualUnidad?.numero}
                              onChange={handleChangeUnidad}
                              inputProps={{
                                name: 'Unidad',
                                id: 'unidad-select-label'
                              }}
                            >

                            {actualComm?.unidads?.map(u => 
                               <option value={u.numero}>{u.numero}</option>
                            )} 
                            </NativeSelect>

                          {/*
                          <Select
                            labelId="unidad-select-label"
                            id="unidad-search"
                            value={actualUnidad?.id}
                            label="Unidad"
                          onChange={handleChangeUnidad}
                          >
                            {actualComm?.unidads?.map(u => 
                               <MenuItem value={u.id}>{u.numero}</MenuItem>
                            )}
                          </Select>
                            */}
                          </FormControl>     
                                                
                        </Grid>

                        <Grid item xs={6} sm={4} md={2}>                
                        <label
                            className={classes.labelPeriodo}
                            style={{ fontWeight: "bold", marginTop: "10px", marginLeft: "10px" }}>
                            Código de Servicio : {actualUnidad?.codigo_servicio} 
                          </label>
                        </Grid>

                        <Grid item>
                            <Button
                              variant="contained"
                              size="small"
                              className={classes.button}
                              startIcon={<SearchIcon />}
                              style={{ marginTop: "5px", marginLeft: "10px" }}
                              disabled={!actualUnidad?.codigo_servicio}
                              onClick={handleBuscarBoleta}
                            >
                              Buscar Boleta
                            </Button>
                        </Grid>
                  </Grid>

                    {/* <div>
                      <Grid container style={{ marginTop: "20px", justify : "center" }}>
                            <Grid item sm={12} xs={12}>
                            <ResponsiveContainer className="ag-theme-material" width="100%" height={250}>
                                      <AgGridReact columnDefs={gridOptions.columnDefs}
                                                  onGridReady={gridOptions.onGridReady}
                                                  localeText={gridOptions.localeText}
                                                  rowData={gridOptions.rowData}>
                                      </AgGridReact>
                                    </ResponsiveContainer>
                              </Grid>
                      </Grid>
                      <StateBottom 
                            className={classes.labelPeriodo}
                            state={stateBottom}
                            boleta={boleta[0]}
                            baseApiUrl={baseApiUrl}
                      />
                    </div> */}
                </div>
              )}
      </Grid>


      <Grid container className={classes.grid_contenidoSeccion} spacing={3} item >

        
        { boleta.length > 0 && (
        <>
        <Grid item xs={12} md={6}  >

          <Container component="main" >
                    <Table className={classes.tbl_contenidoSeccion} size="small" aria-label="simple table">
                      <TableBody>
                      <TableRow>
                          <TableCell colSpan={2} align="left" className={classes.no_borderBottom}>
                            <b>Boletas por pagar</b>
                          </TableCell>

                        </TableRow>

                        <TableRow>
                          <TableCell align="left" className={classes.no_borderBottom}>
                            <b>N° Boleta</b>
                          </TableCell>
                          <TableCell align="left" className={classes.no_borderBottom}>{boleta[0].folio}</TableCell>

                        </TableRow>

                        <TableRow >
                          <TableCell align="left" className={classes.no_borderBottom}><b>Fecha Emisión</b></TableCell>
                          <TableCell align="left" className={classes.no_borderBottom}>
                            <ReactMoment format="DD/MM/YYYY">
                              {boleta[0].fecha_emision}
                            </ReactMoment>
                          </TableCell>
                        </TableRow>

                        <TableRow >
                          <TableCell align="left" className={classes.no_borderBottom}><b>Fecha de Vencimiento</b></TableCell>
                          <TableCell align="left" className={classes.no_borderBottom}>
                            <ReactMoment format="DD/MM/YYYY">
                              {boleta[0].fecha_vencimiento}
                            </ReactMoment>
                          </TableCell>
                        </TableRow>

                        <TableRow >
                          <TableCell align="left" className={classes.no_borderBottom}><b>Monto</b></TableCell>
                          <TableCell align="left" className={classes.no_borderBottom}>{numeral(boleta[0].monto).format('$ 0,0')}</TableCell>
                        </TableRow>

                        <TableRow >
                          <TableCell align="left" className={classes.no_borderBottom}><b>Descargar copia boleta</b></TableCell>
                          {boleta[0].link_pdf !== null &&
                           <TableCell align="left" className={classes.no_borderBottom}><a href={boleta[0].link_pdf} target="_blank" rel="noopener noreferrer"><CloudDownloadIcon /> </a></TableCell>
                            }
                        </TableRow>
                      </TableBody>

                    </Table>
          </Container>
        </Grid>

        <Grid item xs={12} md={6}  >

          <Container component="main" >
                    <Table className={classes.tbl_contenidoSeccion} size="small" aria-label="simple table">
                      <TableBody>

                        <TableRow>
                          <TableCell align="left" className={classes.no_borderBottom}>
                            <b>N° Medidor</b>
                          </TableCell>
                          <TableCell align="left" className={classes.no_borderBottom}>{boleta[0].medidor_id}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" className={classes.no_borderBottom}>
                            <b>Tipo Tarifa / Constante </b>
                          </TableCell>
                          <TableCell align="left" className={classes.no_borderBottom}>{boleta[0].tipo_tarifa.toUpperCase()} / {boleta[0].constante_medida}</TableCell>
                        </TableRow>

                        <TableRow >
                          <TableCell align="left" className={classes.no_borderBottom}><b>Fecha Inicio Lectura</b></TableCell>
                          <TableCell align="left" className={classes.no_borderBottom}>
                            <ReactMoment format="DD/MM/YYYY">
                              {boleta[0].fecha_inicio_lectura}
                            </ReactMoment>
                          </TableCell>
                        </TableRow>
                        <TableRow >
                          <TableCell align="left" className={classes.no_borderBottom}><b>Lectura Inicial</b></TableCell>
                          <TableCell align="left" className={classes.no_borderBottom}>{boleta[0].lectura_periodo_anterior} kWh</TableCell>
                        </TableRow>

                        <TableRow >
                          <TableCell align="left" className={classes.no_borderBottom}><b>Fecha final de Lectura</b></TableCell>
                          <TableCell align="left" className={classes.no_borderBottom}>
                            <ReactMoment format="DD/MM/YYYY">
                              {boleta[0].fecha_fin_lectura}
                            </ReactMoment>
                          </TableCell>
                        </TableRow>

                        <TableRow >
                          <TableCell align="left" className={classes.no_borderBottom}><b>Lectura Final</b></TableCell>
                          <TableCell align="left" className={classes.no_borderBottom}>{boleta[0].lectura_periodo} kWh</TableCell>
                        </TableRow>

                        <TableRow >
                          <TableCell align="left" className={classes.no_borderBottom}><b>Consumo del Período</b></TableCell>
                          <TableCell align="left" className={classes.no_borderBottom}>{boleta[0].consumo_periodo} kWh</TableCell>
                        </TableRow>
                        
                      </TableBody>

                    </Table>
          </Container>
        </Grid>
        </>
        )}

        <Grid item xs={12} md={12} >
          <Container >
                  <Table className={classes.tbl_contenidoSeccion} size="small" aria-label="simple table">
                  <TableBody>
                    <TableRow >
                        <TableCell colSpan={2} align="rigth" className={classes.no_borderBottom}>
                        <StateBottom 
                            // className={classes.labelPeriodo}
                            state={stateBottom}
                            boleta={boleta[0]}
                            isPayEnable={boleta[0]?.isPayEnable }
                            baseApiUrl={baseApiUrl}
                      /></TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>

          </Container>
        </Grid>

      </Grid>      
      

      </Container>
    </div>  
  );

}
